<template>
  <article>
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-building </v-icon>
        <v-toolbar-title class="pl-4"> Empresas </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fecharPagina">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table dense :headers="grid.empresas.cabecalho" :items="grid.empresas.items"
              :items-per-page="grid.empresas.paginacao" hide-default-footer class="elevation-1 pt-4"
              :loading="grid.empresas.carregando">
              <template #item.renovarAutho="{ item }">
                <v-tooltip v-if="item.renovarAutho" bottom>
                  <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon v-on="on">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <td>
                          <v-icon size="16" :color="getColor(item.renovarAutho)">fa-solid fa-circle</v-icon>
                        </td>
                      </div>
                    </v-app-bar-nav-icon>
                  </template>
                  <span>Ação Necessária. Renovar Autorização</span>
                </v-tooltip>
              </template>

              <template v-slot:item.controls="{ item }">
                <v-app-bar-nav-icon @click="carregarDetalhes(item)">
                  <v-icon small>fas fa-search</v-icon>
                </v-app-bar-nav-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->

    <!-- Início Detalhes -->
      <v-dialog v-model="modal.detalhes" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar flat color="#018454">
            <v-app-bar-nav-icon>
              <v-icon color="#FFFFFF">fas fa-building</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title style="color: white;">Detalhes da Empresa ({{ empresaAtual }})</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-app-bar-nav-icon @click="fecharDetalhes()">
              <v-icon color="#FFFFFF">fas fa-times</v-icon>
            </v-app-bar-nav-icon>
          </v-toolbar>

          <v-card-text class="pl-3 pr-3 mt-2">

            <!-- Dados da Empresa -->
            <v-card outlined>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="1">
                    <v-text-field color="#018656" dense outlined label="Código" type="text" autocomplete="off"
                      v-model="detalhes.codigo" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field color="#018656" dense outlined label="CNPJ" type="text" autocomplete="off"
                      v-model="detalhes.cnpj" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field color="#018656" dense outlined label="Razão social" type="text" autocomplete="off"
                      v-model="detalhes.razao_social" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field color="#018656" dense outlined label="Nome fantasia" type="text" autocomplete="off"
                      v-model="detalhes.nome_fantasia" disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field color="#018656" dense outlined label="Telefone" type="text" autocomplete="off"
                      v-model="detalhes.telefone" disabled></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field label="Email para resposta notificações" @input="btnSave" color="#018656" dense outlined v-model="detalhes.email"
                    autocomplete="off" type="text"></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-file-input label="Logo" color="#018656" dense outlined v-model="detalhes.image" @change="btnSave"
                    :disabled="!!detalhes.image ? true : false" accept="image/png, image/jpeg, image/bmp, image/svg"></v-file-input>
                  </v-col>

                  <v-col cols="12" md="5" class="d-flex align-end" style="gap: 20px;">
                    <v-text-field hide-details color="#018656" dense v-model="detalhes.rastreioTokenSisfrete" outlined type="text" readonly
                    label="Sisfrete Token" prepend-icon="far fa-id-card" append-icon="fas fa-times-circle" @change="btnSave" @click:append="confirmDeleteToken()"></v-text-field>

                    <v-btn @change="btnSave" width="fit-content" color="#018656" dense dark @click="generateToken()">Gerar Token</v-btn>
                  </v-col>

                  <v-col cols="12" md="4" offset="1" class="d-flex justify-start">
                    <v-switch inset class="ml-2 pt-0 mt-2" color="#018656" hide-details dense label="Notificações dos pedidos" v-model="detalhes.notifications"
                    @change="btnSave"></v-switch>
                  </v-col>

                  <v-col v-if="saveDetalhes" cols="12" md="2" class="d-flex justify-end">
                    <v-btn @click="alert ? saveDetailsAlert(detalhes) : saveDetails(detalhes)" color="#018656" style="color: white;" dense dark>
                      <v-icon class="mr-2">fas fa-save</v-icon>
                      Salvar
                    </v-btn>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>

            <!-- Botões Integradores Log./Integradoras/Serviços -->
            <v-row dense class="mt-2">
              <v-col cols="12" md="4">
                <v-card @click="setConfig('showIntegradoresLog')" style="display: grid;" class="justify-center pa-4 pb-0"
                  :color="showIntegradoresLog ? '#018656' : '#ebebeb'">
                  <v-icon size="70" :color="showIntegradoresLog ? '#FFFFFF' : '#000000'">fas fa-truck</v-icon>
                  <v-card-text :style="showIntegradoresLog ? 'color: white;' : 'color: black;'" class="text-h6">Integradores Logísticos</v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card @click="setConfig('showIntegradoras')" style="display: grid;" class="justify-center pa-4 pb-0"
                  :color="showIntegradoras ? '#018656' : '#ebebeb'">
                  <v-icon size="70" :color="showIntegradoras ? '#FFFFFF' : '#000000'">fas fa-boxes</v-icon>
                  <v-card-text :style="showIntegradoras ? 'color: white;' : 'color: black;'" class="text-h6">Integradoras</v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card @click="setConfig('showServicos')" style="display: grid;" class="justify-center pa-4 pb-0" :color="showServicos ? '#018656' : '#ebebeb'">
                  <v-icon size="70" :color="showServicos ? '#FFFFFF' : '#000000'">fas fa-truck-loading</v-icon>
                  <v-card-text :style="showServicos ? 'color: white;' : 'color: black;'" class="text-h6">Serviço</v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!--
              * Mostra os integradores Logísticos
              * é chamado quando clica no botão dos integradores Log.
            -->
            <v-row v-if="showIntegradoresLog" justify="center" class="mt-6" style="gap: 80px;">
              <v-card @click="carregarConfig(intLog)" v-for="intLog in integradoresLog" class="pa-2"
               :style="showConfig && titleConfig == intLog.nome ? 'background-color: rgba(1,134,86,40%); border: 2px solid #018656'
               : 'background-color: #ebebeb'">
                <v-img v-if="intLog.img != ''" :src="intLog.img" height="73px" width="110px" contain></v-img>
                <v-card-text v-else style="height: 73px; width: 110px;" class="d-flex align-center black--text font-weight-bold text-center">
                  {{ intLog.nome }}
                </v-card-text>
              </v-card>
            </v-row>

            <!--
              * Mostra as integradoras
              * é chamado quando clica no botão das integradoras
            -->
            <v-row v-if="showIntegradoras" class="mt-0" dense>
              <v-col cols="12" md="10" xl="10">
                <v-card-text class="text-h6 pl-1 pb-0 black--text">Integradoras</v-card-text>
              </v-col>

              <v-col cols="12" md="2" xl="2">
                <v-card-text class="text-h6 pl-1 pb-0 black--text">Canais de Venda</v-card-text>
              </v-col>

              <!-- Chama as Integradoras -->
              <v-col cols="12" md="10" xl="10" class="d-flex justify-space-around" style="border: 1px solid rgba(0,0,0,25%);
              border-radius: 8px; padding: 10px;">
                <v-card v-for="integradora in integradoras" @click="carregarConfig(integradora)" class="pa-2" :style="getStyleIntegradoras(integradora)" >
                  <v-img v-if="integradora.img != ''" :src="integradora.img" height="67px" width="90px" contain></v-img>
                  <v-card-text v-else style="height: 67px; width: 90px;" class="d-flex align-center black--text font-weight-bold">
                    {{ integradora.nome }}
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Chama os Canais de Venda -->
              <v-col cols="12" md="2" xl="2" class="d-flex" style="border: 1px solid rgba(0,0,0,25%); border-radius: 8px; padding: 10px; padding-left: 10px;">
                <v-card v-for="canal in canais_venda" @click="carregarConfig(canal)" class="pa-2 mr-2" :style="getStyleCanais(canal)"
                :class="renovarAutho == '1' && canal.nome == 'Mercado Livre' ? 'erro_canal' : ''">
                  <v-img :src="canal.img" height="67px" width="90px" contain></v-img>
                </v-card>
              </v-col>
            </v-row>

            <!--
              * Mostra os Serviços
              * é chamado quando clica no botão dos Serviços
            -->
            <v-row v-if="showServicos" class="mt-6" dense justify="center" style="gap: 50px;">
              <v-card :disabled="detalhes.notifications == 0" @click="carregarConfig(servico)" v-for="servico in servicos" class="pa-3 cardStyle" :style="showConfig && titleConfig == servico.nome ?
              'background-color: rgba(1,134,86,40%); border: 2px solid #018656; display: grid; justify-content: center;' : 'background-color: #ebebeb; display: grid; justify-content: center;'">
                <v-icon dense color="#000000" size="50">{{ servico.icone }}</v-icon>
                <v-card-text class="d-flex align-center black--text font-weight-bold text-center pt-0 pb-0">{{ servico.nome }}</v-card-text>
              </v-card>
            </v-row>

            <!--
              * Início Configurações
              * é chamado quando clica em alguma integradora log.
              * integradora ou serviço
            -->
            <v-row justify="center" class="mt-10 mb-2" v-if="showConfig">
              <v-card color="rgba(235,235,235,54%)" :width="showNotify() || titleConfig == 'SMS' ? '99%' : '1000px'">
                <v-card-text>
                  <v-card outlined class="pa-4">
                    <v-row>
                      <v-col :cols="showNotify() || titleConfig == 'SMS' ? '6' : '12'">
                        <v-card-text class="text-h6 pl-0 pt-0 black--text d-flex align-start pr-0">
                          <span class="mr-1" v-if="titleConfig == 'Correios' || titleConfig == 'JadLog' || titleConfig == 'FTP Proceda'">
                            Rastreio</span>
                          <span class="mr-1" v-else-if="titleConfig == 'Email' || titleConfig == 'SMS'">Notificações</span>
                          <span class="mr-1" v-else>Configurações </span> {{ titleConfig }}

                          <v-tooltip top v-if="titleConfig == 'Mercado Livre' && renovarAutho == '1'">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" color="orange" small class="ml-3">
                                fas fa-exclamation-triangle
                              </v-icon>
                            </template>
                            <span>Renovar Autorização!</span>
                          </v-tooltip>

                          <span class="ml-1" v-if="titleConfig == 'Email' || titleConfig == 'SMS'">- Todos os pedidos</span>

                          <v-spacer></v-spacer>
                          <span v-if="titleConfig == 'Bling' || titleConfig == 'AnyMarket' || titleConfig == 'Tray' || titleConfig == 'Wake'
                          || titleConfig == 'Fast Commerce' || titleConfig == 'Tiny' || titleConfig == 'Aton'">
                            <v-checkbox v-model="IntBaixarPedidos" dense hide-details color="#018656" label="Baixar Pedidos" class="mr-2 pt-0"
                            :value="setValueCheck()" readonly @click="alteraIntegracao(titleConfig)"></v-checkbox>
                          </span>
                          <v-btn v-if="!showNotify()" icon @click="showConfig = false;">
                            <v-icon color="error">fas fa-times</v-icon>
                          </v-btn>
                        </v-card-text>
                      </v-col>

                      <v-col v-if="showNotify() || titleConfig == 'SMS'" cols="6" :style="titleConfig != 'Mensagens' ? 'border-left: 2px solid #ebebeb;' : ''">
                        <div class="d-flex align-start">
                          <v-card-text v-if="titleConfig != 'SMS' && titleConfig != 'Mensagens'" class="text-h6 pl-0 pt-0 black--text d-flex">
                            Notificações -
                            <span class="ml-1" v-if="titleConfig != 'Tray' && titleConfig != 'Wake' && titleConfig != 'Fast Commerce'">
                              <span v-if="tipo_notify != 0 && tipo_notify != 1">{{ tipo_notify }}</span>
                              <span v-else> {{ tipo_notify == 1 ? 'Todos Pedidos' : 'Somente' }} </span>
                             </span>
                            <span v-else>
                              <v-select dense color="#018656" outlined hide-details :items="itemsNotify" item-value="codigo" item-text="nome"
                              v-model="notifications.desserts[indice].type" class="ml-2" @change="selectModoEnvio()">
                              </v-select>
                            </span>
                          </v-card-text>
                          <v-card-text v-else-if="titleConfig == 'SMS'" class="text-h6 pl-0 pt-0 black--text">Mensagem</v-card-text>
                          <v-card-text v-else></v-card-text>

                          <v-btn icon @click="showConfig = false;">
                            <v-icon color="error">fas fa-times</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!-- Correios -->
                      <template v-if="titleConfig == 'Correios'">
                        <v-col cols="12" md="3">
                          <v-text-field @input="btnSave" dense color="#018656" label="Contrato" v-model="detalhes.contratocorreios" hide-details outlined type="text"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field @input="btnSave" dense color="#018656" label="Cartão" v-model="detalhes.cartaocorreios" hide-details outlined type="text"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field @input="btnSave" dense color="#018656" label="Email" v-model="detalhes.emailcorreios" hide-details outlined type="email" autocomplete="off"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field @input="btnSave" dense color="#018656" label="Senha" v-model="detalhes.senhacorreios" hide-details outlined type="password"
                          :append-icon="showPass ? 'far fa-eye' : 'far fa-eye-slash'" :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass"
                          autocomplete="off"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="pt-2">
                          <v-text-field @input="btnSave" dense color="#018656" label="Token Autorização" v-model="detalhes.tokencorreios" hide-details outlined type="text"></v-text-field>
                        </v-col>
                      </template>

                      <!-- JadLog -->
                      <template v-else-if="titleConfig == 'JadLog'">
                        <v-col cols="12">
                          <v-text-field outlined color="#018656" dense v-model="detalhes.tokenjad" hide-details label="Token Jadlog"
                          type="text" clearable @input="btnSave"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="d-flex align-center">
                          <v-radio-group dense label="Rastrear por:-" v-model="detalhes.rastreioJad_tipo" row>
                            <v-radio label="Shipment Id" :value="0" color="#018656"></v-radio>
                            <v-radio label="Danfe" :value="1" color="#018656"></v-radio>
                            <v-radio label="Nota Fiscal" :value="2" color="#018656"></v-radio>
                            <v-radio label="Cte" :value="3" color="#018656"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </template>

                      <!-- FTP Proceda -->
                      <template v-else-if="titleConfig == 'FTP Proceda'">
                        <v-col cols="12">
                          <v-text-field dense outlined color="#018656" hide-details label="Apelido Pasta FTP"
                          v-model="detalhes.apeliFtpProceda" type="text" clearable autocomplete="off" @input="btnSave"
                          ></v-text-field>
                        </v-col>
                      </template>

                      <!-- Integrador Logístico Sisfrete -->
                      <template v-else-if="titleConfig == 'Integrador Logístico Sisfrete'">
                        <v-col cols="12" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" class="d-flex align-start">
                              <v-text-field label="Api Key Integrador Logístico Sisfrete"
                                color="#018656" hide-details dense outlined v-model="detalhes.apikeyIntLog" autocomplete="off"
                                prepend-icon="far fa-id-card" :append-icon="detalhes.apikeyIntLog ? 'fas fa-times-circle' : ''"
                                @click:append="DeleteApiKey()" @input="btnSave(), alert = true">
                              </v-text-field>

                              <v-btn class="ml-2" @change="btnSave" width="fit-content" color="#018656" dense dark @click="generateApiKey()">
                                Gerar Api Key
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Bling -->
                      <template v-else-if="titleConfig == 'Bling'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense>
                            <v-col cols="12" class="d-flex align-start justify-center" style="gap: 20px;">
                              <v-text-field dense v-model="detalhes.appSisfreteBlingV3" color="#018656" hide-details outlined
                                label="Token Bling" readonly autocomplete="off" @input="btnSave">
                              </v-text-field>

                              <v-btn @click="generateTokenBling()" @change="btnSave" width="fit-content" color="#018656" dense dark>Gerar Token</v-btn>
                            </v-col>

                            <v-col cols="12" class="d-flex align-start justify-end mt-2">
                              <v-btn v-if="detalhes.appSisfreteBlingV3 != '' && detalhes.appSisfreteBlingV3 != null"
                              width="fit-content" color="#FF0000" style="color: white;" dense dark @click="cancelarIntegracao('Bling')">
                                <v-icon dense class="mr-2" color="white">fas fa-ban</v-icon>
                                Cancelar Integração
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox @change="btnSave" class="mt-0" color="#018656" dense :label="item.label"
                              :value="item.value" v-model="notifications.desserts[indice].timeline.selected"></v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- NuvemShop -->
                      <template v-else-if="titleConfig == 'NuvemShop'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" md="5">
                              <v-text-field color="#018656" dense v-model="detalhes.urlNuvemShop" outlined hide-details
                              label="URL da loja em NuvemShop" autocomplete="off" clearable @input="btnSave"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="2" v-if="detalhes.userIdNS">
                              <v-text-field color="#018656" dense v-model="detalhes.userIdNS" outlined hide-details
                              label="Id" autocomplete="off" readonly @input="btnSave"></v-text-field>
                            </v-col>

                            <v-col cols="12" :md="!detalhes.userIdNS ? 7 : 2">
                              <v-text-field color="#018656" dense v-model="detalhes.tokenSisfreteNS" outlined hide-details
                              label="Token Utilizado" autocomplete="off" readonly @input="btnSave(), alert = true"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="3" v-if="detalhes.userIdNS">
                              <v-select dense v-model="detalhes.enviosSisfreteNS" outlined hide-details
                              label="Método de Envio" autocomplete="off" :items="statusNuvem" readonly
                              :color="!detalhes.enviosSisfreteNS ? 'red' : '#018656'" @change="btnSave"></v-select>
                            </v-col>

                            <v-col cols="12" md="5" v-if="detalhes.userIdNS">
                              <v-select dense v-model="detalhes.opcaoSisfreteNS" outlined hide-details
                              label="Opções de Envio" autocomplete="off" :items="statusNuvem" readonly
                              :color="!detalhes.enviosSisfreteNS ? 'red' : '#018656'" @change="btnSave"></v-select>
                            </v-col>

                            <v-col cols="12" md="5" v-if="detalhes.userIdNS">
                              <v-select dense v-model="detalhes.modoSisfreteNS" outlined hide-details
                              label="Modo Cotação" autocomplete="off" :items="modoNuvem" @change="btnSave"
                              :color="!detalhes.modoSisfreteNS ? 'red' : '#018656'"></v-select>
                            </v-col>

                            <v-col cols="12" md="2" v-if="detalhes.urlNuvemShop" class="d-flex align-start">
                              <v-btn @click="authorizationNS(detalhes)" color="#018656" dense dark width="100%">
                                {{ urlNuvemShop == '' ? "Instalar" : "Remover" }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox v-model="notifications.desserts[indice].timeline.selected" @change="btnSave" class="mt-0" color="#018656" dense :label="item.label" :value="item.value">
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- AnyMarket -->
                      <template v-else-if="titleConfig == 'AnyMarket'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" md="4">
                              <v-text-field color="#018656" label="Token AnyMarket" hide-details dense outlined
                              v-model="detalhes.apptokenAny" @input="btnSave(), alert = true">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                              <v-text-field color="#018656" label="Oi AnyMarket" hide-details dense outlined
                              v-model="detalhes.appoiAny" @input="btnSave">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                              <v-select dense v-model="detalhes.modoSisfreteAny" outlined hide-details
                              label="Modo Integração" autocomplete="off" :items="modoNuvem" @change="btnSave"
                              :color="!detalhes.modoSisfreteAny ? 'red' : '#018656'"></v-select>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox v-model="notifications.desserts[indice].timeline.selected" @change="btnSave" class="mt-0" color="#018656" dense :label="item.label"
                              :value="item.value">
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Wake -->
                      <template v-else-if="titleConfig == 'Wake'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" md="4">
                              <v-text-field color="#018656" label="Token Wake" hide-details dense outlined
                              v-model="detalhes.apptokenWake" @input="btnSave(), alert = true">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="4">
                              <v-select dense v-model="detalhes.modoSisfreteWake" outlined hide-details
                              label="Modo Integração" autocomplete="off" :items="modoNuvem" @change="btnSave"
                              :color="!detalhes.modoSisfreteWake ? 'red' : '#018656'"></v-select>
                            </v-col>

                            <v-col cols="12" md="4">
                              <v-btn width="fit-content" color="#018656" dense dark @click="fnStatusPedidosWake()">
                                {{ detalhes.appstatuspedidosWake.length > 0 ? "Status dos Pedidos" : "Selecionar os Status dos Pedidos" }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox v-model="notifications.desserts[indice].timeline.selected" @change="btnSave" class="mt-0" color="#018656" dense :label="item.label" :value="item.value"                              >
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Tray -->
                      <template v-else-if="titleConfig == 'Tray'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" md="6">
                              <v-text-field v-model="detalhes.appTray_api_address" color="#018656" outlined dense label="URL da Loja em Tray" readonly
                              autocomplete="off" @change="btnSave"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                              <v-text-field v-model="detalhes.appTray_code" color="#018656" outlined dense label="Code" readonly
                              autocomplete="off" @change="btnSave"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                              <v-text-field v-model="detalhes.appTray_token" color="#018656" outlined dense label="Token Utilizado" readonly
                              autocomplete="off" @change="btnSave(), alert = true"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-end">
                              <v-btn v-if="detalhes.appTray_token != '' && detalhes.appTray_token != null"
                              width="fit-content" color="#FF0000" style="color: white;" dense dark @click="cancelarIntegracao('Tray')">
                                <v-icon dense class="mr-2" color="white">fas fa-ban</v-icon>
                                Cancelar Integração
                              </v-btn>

                              <v-btn class="ml-2" width="fit-content" color="#018656" dense dark @click="fnStatusPedidosTray()">
                                {{ detalhes.appTray_status_pedidos.length > 0 ? "Status dos Pedidos" : "Selecionar os Status dos Pedidos" }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox v-model="notifications.desserts[indice].timeline.selected" @change="btnSave" class="mt-0" color="#018656" dense :label="item.label"
                              :value="item.value">
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Aleph CRM -->
                      <template v-else-if="titleConfig == 'Aleph CRM'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" md="5">
                              <v-text-field label="Id" clearable color="#018656" hide-details dense outlined
                              v-model="detalhes.accountid" autocomplete="off" prepend-icon="far fa-id-card" @input="btnSave">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="5">
                              <v-text-field label="Key" clearable color="#018656" hide-details dense outlined
                              v-model="detalhes.apikey" autocomplete="off" prepend-icon="far fa-id-card" @input="btnSave">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="2" class="d-flex align-start">
                              <v-switch @change="btnSave" class="mt-0" inset label="Baixar Produtos" dense v-model="detalhes.productAlephcrm" color="#018656">
                              </v-switch>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox v-model="notifications.desserts[indice].timeline.selected" @change="btnSave" class="mt-0" color="#018656" dense :label="item.label" :value="item.value"                              >
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Tiny -->
                      <template v-else-if="titleConfig == 'Tiny'">
                        <v-col :cols="showNotify() ? '6' : '12'">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" class="d-flex">
                              <v-text-field label="Token Tiny" v-model="detalhes.tokenSisfreteTiny" color="#018656" outlined
                              dense clerable autocomplete="off" hide-details @input="btnSave"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense justify="center">
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;" v-if="item.label == 'Postado' || item.label == 'Entregue'">
                              <v-checkbox @change="btnSave" v-model="notifications.desserts[indice].timeline.selected" class="mt-0" color="#018656" dense :label="item.label"
                              :value="item.value"></v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Aton -->
                      <template v-else-if="titleConfig == 'Aton'">
                        <v-col cols="12">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" class="d-flex">
                              <v-text-field label="Token Aton" v-model="detalhes.tokenSisfreteAton" dense outlined color="#018656"
                              hide-details @input="btnSave" autocomplete="off"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Mercado Livre -->
                      <template v-else-if="titleConfig == 'Mercado Livre'">
                        <v-col :cols="showNotify() ? '6' : '12'">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" class="d-flex">
                              <v-text-field label="Autorização ML APP Sisfrete" v-model="detalhes.authorizationML" color="#018656" outlined
                              dense readonly clerable autocomplete="off" hide-details @change="btnSave"></v-text-field>

                              <v-btn class="ml-3" width="fit-content" @click="authorizationML()" :color="getColor(renovarAutho, '#018656')" dense dark>
                                {{ !detalhes.authorizationML ? "Autorizar" : "Renovar" }}
                              </v-btn>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-end mt-2">
                              <v-btn v-if="detalhes.authorizationML != '' && detalhes.authorizationML != null"
                              width="fit-content" color="#FF0000" style="color: white;" dense dark @click="cancelarIntegracao('Mercado Livre')">
                                <v-icon class="mr-2" dense color="white">fas fa-ban</v-icon>
                                Cancelar Canal
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" style="border-left: 2px solid #ebebeb; flex-direction: column;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox v-model="notifications.desserts[indice].timeline.selected" @change="btnSave" class="mt-0" color="#018656" dense :label="item.label" :value="item.value">
                              </v-checkbox>
                            </div>
                          </v-row>

                          <v-row dense class="mt-5">
                            <v-col cols="6">
                              <v-textarea v-model="detalhes.message_notify_start" label="Produto Enviado" color="#018656" dense
                              auto-grow outlined @input="btnSave"></v-textarea>
                            </v-col>

                            <v-col cols="6">
                              <v-textarea v-model="detalhes.message_notify_end" label="Produto Entregue" color="#018656" dense
                              auto-grow outlined @input="btnSave"></v-textarea>
                            </v-col>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Shopee -->
                      <template v-else-if="titleConfig == 'Shopee'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" md="2" v-if="appShopeeShopId">
                              <v-text-field label="Shop_Id" v-model="appShopeeShopId" color="#018656" outlined
                              dense readonly autocomplete="off" hide-details @change="btnSave"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="5" v-if="detalhes.appShopeeSellerCode">
                              <v-text-field label="Seller Code" v-model="detalhes.appShopeeSellerCode" color="#018656" outlined
                              dense readonly autocomplete="off" hide-details @change="btnSave"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="5">
                              <v-text-field label="ERP Code" v-model="detalhes.appShopeeErpCode" color="#018656" outlined
                              dense readonly autocomplete="off" hide-details @change="btnSave"></v-text-field>
                            </v-col>

                            <v-col v-if="appShopeeShopId" cols="12" :md="detalhes.appShopeeSellerCode ? '3' : '5'">
                              <v-text-field label="Token Utilizado" v-model="detalhes.appShopeeToken" color="#018656" outlined
                              dense readonly autocomplete="off" hide-details @change="btnSave"></v-text-field>
                            </v-col>

                            <v-col class="d-flex align-start justify-end">
                              <v-btn v-if="!detalhes.appShopeeSellerCode" @click="urlConsulta(1)" width="fit-content" color="#018656" dark>
                                Autorizar APP Seller
                              </v-btn>

                              <v-btn v-if="!detalhes.appShopeeErpCode" @click="urlConsulta(2)" class="ml-2" width="fit-content" color="#018656" dark>
                                Autorizar App Erp
                              </v-btn>

                              <v-btn v-if="appShopeeShopId" @click="fnRegistrarToken(21)" class="ml-2" color="#018656" dark>
                                {{ !!detalhes.appShopeeToken ? "Alterar Token Registrado" : "Registrar Token" }}
                              </v-btn>
                            </v-col>

                            <v-col cols="12" class="d-flex justify-end mt-2">
                              <v-btn v-if="detalhes.appShopeeToken != '' && detalhes.appShopeeToken != null"
                              width="fit-content" color="#FF0000" style="color: white;" dense dark @click="cancelarIntegracao('Shopee')">
                                <v-icon class="mr-2" dense color="white">fas fa-ban</v-icon>
                                Cancelar Canal
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense justify="center">
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;" v-if="item.label == 'Postado' || item.label == 'Entregue'">
                              <v-checkbox @change="btnSave" v-model="notifications.desserts[indice].timeline.selected" class="mt-0" color="#018656" dense :label="item.label" :value="item.value">
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- FastCommerce -->
                      <template v-else-if="titleConfig == 'Fast Commerce'">
                        <v-col :cols="showNotify() ? '6' : '12'" class="d-flex">
                          <v-row dense class="pl-1 pr-1">
                            <v-col cols="12" md="3">
                              <v-text-field label="Id da Loja"
                                clearable color="#018656" hide-details dense outlined v-model="detalhes.FastCommerce.StoreId" @input="btnSave">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                              <v-text-field label="Nome da Loja"
                                clearable color="#018656" hide-details dense outlined v-model="detalhes.FastCommerce.StoreName" @input="btnSave">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                              <v-text-field autocomplete="null" label="Usuário"
                                clearable color="#018656" hide-details dense outlined v-model="detalhes.FastCommerce.Username" @input="btnSave">
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                              <v-text-field autocomplete="null" label="Senha Usuário"
                                clearable color="#018656" hide-details dense outlined v-model="detalhes.FastCommerce.Password" :append-icon="showPassFast ? 'far fa-eye' : 'far fa-eye-slash'" :type="showPassFast ? 'text' : 'password'"
                                @click:append="showPassFast = !showPassFast" @input="btnSave">
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col v-if="showNotify()" cols="6" class="d-flex" style="border-left: 2px solid #ebebeb;">
                          <v-row dense>
                            <div class="d-flex justify-center" v-for="item in notifications.desserts[indice].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox v-model="notifications.desserts[indice].timeline.selected" @change="btnSave" class="mt-0" color="#018656" dense :label="item.label"
                              :value="item.value">
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>
                      </template>

                      <!-- Email -->
                      <template v-else-if="titleConfig == 'Email'">
                        <div v-for="item in notifications.desserts[1].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                          <v-checkbox @change="btnSave" class="mt-0" color="#018656" dense :label="item.label" :value="item.value"
                          v-model="notifications.desserts[1].timeline.selected">
                          </v-checkbox>
                        </div>
                      </template>

                      <!-- SMS -->
                      <template v-else-if="titleConfig == 'SMS'">
                        <v-col cols="6" class="d-flex">
                          <v-row dense>
                            <div v-for="item in notifications.desserts[0].timeline.items" style="flex: 0 0 20%; max-width: 20%;">
                              <v-checkbox @change="btnSave" class="mt-0" color="#018656" dense :label="item.label" :value="item.value"
                              v-model="notifications.desserts[0].timeline.selected">
                              </v-checkbox>
                            </div>
                          </v-row>
                        </v-col>

                        <v-col cols="6" class="d-flex pl-2 pr-2" style="border-left: 2px solid #ebebeb;">
                          <v-textarea label="Mensagem para notificação SMS" v-model="detalhes.message_notify_sms" outlined
                          dense auto-grow @input="btnSave(sms = true)" color="#018656" counter="160" :rules="regrasSMS"></v-textarea>
                        </v-col>
                      </template>
                    </v-row>

                  </v-card>
                </v-card-text>
              </v-card>
            </v-row>

            <v-row class="mt-2" dense v-if="showIntegradoras && !showConfig">
              <v-col cols="12" class="d-flex">
                <v-card outlined class="pa-2" style="width: fit-content;">
                  <div class="d-flex" style="width: fit-content;">
                    <v-btn class="elevation-0 pa-0" outlined max-width="25px" min-width="25px" max-height="20px" color="#018656"></v-btn>

                    <span class="ml-2 pa-0"> - Integradoras Ativas</span>
                  </div>

                  <div class="d-flex mt-2" style="width: fit-content;">
                    <v-btn class="elevation-0 pa-0" outlined max-width="25px" min-width="25px" max-height="20px" color="#FF0000"></v-btn>
                    <span class="ml-2 pa-0"> - Erro na Autenticação</span>
                  </div>

                </v-card>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- Fim Detalhes -->

    <!-- Inicio Status Pedidos Tray -->
    <v-dialog v-model="showStatusPedidosTray" class="text-center" persistent width="900">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-toolbar-title class="pl-4">
            {{ getTitleStatusTray }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-app-bar-nav-icon @click="showStatusPedidosTray = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="5">
              <v-data-table
                dense
                v-model="detalhes.appTray_status_pedidos"
                :headers="statuspedidostray.headers"
                :items="detalhes.appTray_status_pedidos"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
            <v-col cols="12" md="7">
              <v-data-table
                dense
                v-model="detalhes.appTray_status_pedidos"
                :headers="statuspedidostray.headers"
                :items="this.statuspedidostray.conteudo"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                :loading="statuspedidostray.carregando"
                show-select
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="#018656" @click="btnSave(), showStatusPedidosTray = false">
            Confirmar Seleção
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- Fim Status Pedidos Tray -->

    <!-- Inicio Status Pedidos Wake-->
    <v-dialog v-model="framePedidosWake" class="text-center" persistent width="900">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-toolbar-title class="pl-4">
            {{ getTitleStatusWake }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-app-bar-nav-icon @click="framePedidosWake = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="5">
              <v-data-table
                dense
                v-model="detalhes.appstatuspedidosWake"
                :headers="statuspedidosWake.headers"
                :items="detalhes.appstatuspedidosWake"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
            <v-col cols="12" md="7">
              <v-data-table
                dense
                v-model="detalhes.appstatuspedidosWake"
                :headers="statuspedidosWake.headers"
                :items="this.statuspedidosWake.conteudo"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                :loading="statuspedidosWake.carregando"
                show-select
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>

          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="#018656" @click="btnSave(), framePedidosWake = false">
            Confirmar Seleção
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- Fim Status Pedidos Wake-->

    <!-- Inicio url consulta Shopee -->
    <v-dialog v-model="urlShopee" class="text-center" persistent width="700">
      <v-card>
        <v-card-text style="padding-top: 17px">
          {{ this.strUrlShopee }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="#018656" text @click="urlOpen()">
            ABRIR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warnning" text @click="urlShopee = false">
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim url consulta Shopee -->

    <!-- Inicio Registrar Token Shopee -->
    <v-dialog v-model="frameRegistrarToken" class="text-center" persistent width="700">
      <v-card>
        <v-toolbar color="#018656" dark>
          <v-toolbar-title class="pl-4">
            Registrar Token {{  }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-app-bar-nav-icon @click="frameRegistrarToken = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                dense
                v-model="canaisToken.codigo"
                :headers="canaisToken.headers"
                :items="canaisToken.conteudo"
                hide-default-footer
                disable-pagination
                class="elevation-1 pt-4"
                :loading="canaisToken.carregando"
                single-select
                show-select
                item-key="id"
                fixed-header
                height="400px"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="#018656" @click="saveRegistrarToken()">
            Gravar Registro
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim Registrar Token Shopee -->
  </article>
</template>

<script>
import { baseApiUrl, baseApiUrlPersys, MLAppSisfrete, showError, showSuccess } from '@/global';
import axios from 'axios';
import { fecharPagina } from '../functions/fechar_pagina';

export default {
  name: 'NovoEmpresas',
  data: () => ({
    codigo_empresa: null,
    showPass: false,
    showPassFast: false,
    grid: {
      empresas: {
        cabecalho: [
          { text: "Código", value: "codigo", sortable: false },
          { text: "CNPJ", value: "cnpj", sortable: false },
          { text: "Razão social", value: "razao_social", sortable: false },
          { text: "Nome fantasia", value: "nome_fantasia", sortable: false },
          { text: "Logo", value: "imagem", sortable: false },
          { text: "Falhas", value: "renovarAutho", sortable: false },
          { text: "Ações", value: "controls", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        porPagina: 10,
      },
    },
    modal: {
      detalhes: false,
    },
    urlNuvemShop: '',

    detalhes: {
      email: '',
      rastreioTokenSisfrete: "",
      pedidosFastCommerce: false,
      FastCommerce: {
        StoreId: '',
        StoreName: '',
        Username: '',
        Password: '',
      },
      rastreioCorreios: false,
      emailcorreios: '',
      senhacorreios: '',
      tokencorreios: '',
      appSisfreteBlingV3: false,
      rastreioJad: false,
      tokenjad: "",
      rastreioJad_tipo: false,
      rastreioAlephcrm: false,
      productAlephcrm: false,
      apikey: '',
      accountid: '',
      ftpProceda: false,
      apeliFtpProceda: '',
      appSisfreteML: false,
      authorizationML: '',
      notifications: false,
      notify: [],
      image: '',
      message_notify_start: '',
      message_notify_end: '',
      message_notify_sms: '',
      integracao: false,
      rastreioBling: false,
      appSisfreteNS: false,
      urlNuvemShop: '',
      userIdNS: '',
      tokenSisfreteNS: '',
      enviosSisfreteNS: false,
      opcaoSisfreteNS: false,
      modoSisfreteNS: false,
      appSisfreteTray: false,
      appTray_code: '',
      appTray_api_address: '',
      appTray_token: '',
      appTray_status_pedidos: [],
      appSisfreteAny: false,
      apptokenAny: '',
      appoiAny: '',
      modoSisfreteAny: false,
      appSisfreteWake: false,
      apptokenWake: '',
      modoSisfreteWake: false,
      appstatuspedidosWake: [],
      appSisfreteShopee: false,
      appShopeeSellerCode: '',
      appShopeeSellerShopId: '',
      appShopeeErpCode: '',
      appShopeeErpShopId: '',
      appShopeeToken: null,
      pedidosIntLog: false,
      apikeyIntLog: '',
      appSisfreteTiny: 0,
      tokenSisfreteTiny: '',
      appSisfreteAton: 0,
      tokenSisfreteAton: '',
    },

    detalhes_edit: {
      email: '',
      rastreioTokenSisfrete: "",
      pedidosFastCommerce: false,
      FastCommerce: {
        StoreId: '',
        StoreName: '',
        Username: '',
        Password: '',
      },
      rastreioCorreios: false,
      emailcorreios: '',
      senhacorreios: '',
      tokencorreios: '',
      appSisfreteBlingV3: false,
      rastreioJad: false,
      tokenjad: "",
      rastreioJad_tipo: false,
      rastreioAlephcrm: false,
      productAlephcrm: false,
      apikey: '',
      accountid: '',
      ftpProceda: false,
      apeliFtpProceda: '',
      appSisfreteML: false,
      authorizationML: '',
      notifications: false,
      notify: [],
      image: '',
      message_notify_start: '',
      message_notify_end: '',
      message_notify_sms: '',
      integracao: false,
      rastreioBling: false,
      appSisfreteNS: false,
      urlNuvemShop: '',
      userIdNS: '',
      tokenSisfreteNS: '',
      enviosSisfreteNS: false,
      opcaoSisfreteNS: false,
      modoSisfreteNS: false,
      appSisfreteTray: false,
      appTray_code: '',
      appTray_api_address: '',
      appTray_token: '',
      appTray_status_pedidos: [],
      appSisfreteAny: false,
      apptokenAny: '',
      appoiAny: '',
      modoSisfreteAny: false,
      appSisfreteWake: false,
      apptokenWake: '',
      modoSisfreteWake: false,
      appstatuspedidosWake: [],
      appSisfreteShopee: false,
      appShopeeSellerCode: '',
      appShopeeSellerShopId: '',
      appShopeeErpCode: '',
      appShopeeErpShopId: '',
      appShopeeToken: null,
      pedidosIntLog: false,
      apikeyIntLog: '',
      appSisfreteTiny: 0,
      tokenSisfreteTiny: '',
      appSisfreteAton: 0,
      tokenSisfreteAton: '',
    },

    notifications: {
      selected: [],
      headers: [
        { text: "Notificar", value: "channel", sortable: false },
        { text: "Como?", value: "type", sortable: false },
        { text: "Time Lime", value: "timeline", sortable: false },
      ],
      desserts: [],
    },

    notifications_edit: {
      selected: [],
      headers: [
        { text: "Notificar", value: "channel", sortable: false },
        { text: "Como?", value: "type", sortable: false },
        { text: "Time Lime", value: "timeline", sortable: false },
      ],
      desserts: [],
    },

    tipo_notify: null,
    indice: null,

    timeline: {
      selected: [],
      items: [
        { label: "Postado", value: 1 },
        { label: "Em Trânsito", value: 2 },
        { label: "Saiu Entrega", value: 3 },
        { label: "Entregue", value: 4 },
        { label: "Alerta", value: 5 },
      ]
    },

    statuspedidostray: {
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      conteudo: [],
      carregando: false,
    },

    showStatusPedidosTray: false,

    statuspedidosWake: {
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      conteudo: [],
      carregando: false,
    },

    framePedidosWake: false,

    canaisToken: {
      headers: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nome", value: "nome", sortable: false },
        { text: "Token", value: "token", sortable: false },
        { text: "Canal", value: "canal", sortable: false },
      ],
      codigo: [],
      conteudo: [],
      carregando: false,
    },

    frameRegistrarToken: false,

    integradores: [],
    showIntegradoresLog: false,
    showIntegradoras: false,
    showServicos: false,
    integradoresLog: [
      { nome: 'Correios', img: '/transportadoras/correios.svg' },
      { nome: 'JadLog', img: '/transportadoras/logo_jadlog.png' },
      { nome: 'FTP Proceda', img: '' },
    ],

    itemsNotify: [
      { codigo: 0, nome: "Somente" },
      { codigo: 1, nome: "Todos Pedidos" }
    ],

    integradoras: [
      { nome: 'Bling', img: '/integradoras/bling.png' },
      { nome: 'NuvemShop', img: '/integradoras/Nuvemshop-logo.png' },
      { nome: 'AnyMarket', img: '/integradoras/anymarket.png' },
      { nome: 'Wake', img: 'integradoras/wake.png' },
      { nome: 'Tray', img: 'integradoras/tray.png' },
      { nome: 'Fast Commerce', img: 'integradoras/fast.png' },
      { nome: 'Aleph CRM', img: 'integradoras/aleph-logo.png' },
      { nome: 'Tiny', img: '/integradoras/tiny.png' },
      { nome: 'Aton', img: '/integradoras/aton.png' },
      { nome: 'Integrador Logístico Sisfrete', img: '' }
    ],
    canais_venda: [
      { nome: 'Mercado Livre', img: 'canais/mercado-livre.svg' },
      { nome: 'Shopee', img: 'canais/shopee-logo.png' },
    ],
    servicos: [
      { nome: 'Email', icone: 'fas fa-envelope' },
      { nome: 'SMS', icone: 'fas fa-mobile' }
    ],
    titleConfig: '',
    title: '',
    showConfig: false,
    empresaAtual: '',
    statusNuvem: [{ text: "Não importado", value: 0 }, { text: "Importado", value: 1 }],
    modoNuvem: [{ text: "Homologação", value: 0 }, { text: "Produção", value: 1 }],
    windowNew: {
      url: `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${MLAppSisfrete}&redirect_uri=https://api.cliente.sandbox.sisfrete.com.br/api/v1/AppSisfreteML`,
      name: 'AuthorizationML',
      size: 'width=500,height=300',
      ret: '',
    },
    strUrlShopee: '',
    urlShopee: false,
    appShopeeShopId: '',
    appShopeeShopId_edit: '',
    saveData: false,
    saveDetalhes: false,
    saveCorreios: false,
    saveJad: false,
    saveProceda: false,
    saveBling: false,
    saveNuvem: false,
    saveAnyMarket: false,
    saveWake: false,
    saveFast: false,
    saveTray: false,
    saveAleph: false,
    saveIntLog: false,
    saveTiny: false,
    saveAton: false,
    saveMercadoLivre: false,
    saveShopee: false,
    saveEmail: false,
    saveSMS: false,

    saveNotifyBling: false,
    saveNotifyNuvem: false,
    saveNotifyAnyMarket: false,
    saveNotifyWake: false,
    saveNotifyFast: false,
    saveNotifyTray: false,
    saveNotifyAleph: false,
    saveNotifyIntLog: false,
    saveNotifyTiny: false,
    saveNotifyMercadoLivre: false,
    saveNotifyShopee: false,

    notifica: [],
    selectModoNotify: [],
    IntBaixarPedidos: false,

    regrasSMS: [
      value => {
        var format = /[€{}\[\]\\]+/;

        if(value != null){
          if(value.length > 160) {
            return 'O campo excedeu o limíte máximo de caracteres.'
          }
        }

        if(format.test(value)){
          return 'Esse campo não aceita caracteres especiais.'
        }

        return true;
      }
    ],

    alert: false
  }),

  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
    this.carregarEmpresas();
    this.carregarTokenSisfrete();
    this.carregarIntegradoras();
  },

  methods: {
    fecharPagina,

    fecharDetalhes() {
      this.alert = false;

      this.detalhes.codigo = null;
      this.detalhes.cnpj = null;
      this.detalhes.razao_social = null;
      this.detalhes.nome_fantasia = null;
      this.detalhes.telefone = null;
      this.detalhes.email = null;
      this.detalhes.image = null;

      this.detalhes.integradoras = null;
      this.detalhes.notifications = null;

      this.detalhes.contratocorreios = null;
      this.detalhes.cartaocorreios = null;
      this.detalhes.rastreioCorreios = null;
      this.detalhes.emailcorreios = null;
      this.detalhes.senhacorreios = null;
      this.detalhes.tokencorreios = null;

      this.detalhes.tokenjad = null;
      this.detalhes.rastreioJad_tipo = null;

      this.detalhes.ftpProceda = null;

      this.detalhes.apeliFtpProceda = null;

      this.detalhes.appSisfreteBlingV3 = null;

      this.detalhes.urlNuvemShop = null;
      this.detalhes.userIdNS = null;
      this.detalhes.tokenSisfreteNS = null;
      this.detalhes.enviosSisfreteNS = null;
      this.detalhes.opcaoSisfreteNS = null;
      this.detalhes.modoSisfreteNS = null;

      this.detalhes.apptokenAny = null;
      this.detalhes.appoiAny = null;
      this.detalhes.modoSisfreteAny = null;

      this.detalhes.apptokenWake = null;
      this.detalhes.modoSisfreteWake = null;

      this.detalhes.appstatuspedidosWake = [];

      this.detalhes.FastCommerce.StoreId = null;
      this.detalhes.FastCommerce.StoreName = null;
      this.detalhes.FastCommerce.Username = null;
      this.detalhes.FastCommerce.Password = null;

      this.detalhes.appTray_code = null;
      this.detalhes.appTray_api_address = null;
      this.detalhes.appTray_token = null;

      this.detalhes.apikey = null;
      this.detalhes.accountid = null;
      this.detalhes.productAlephcrm = null;

      /** Integrador Logístico Sisfrete */
      this.detalhes.apikeyIntLog = null;

      this.detalhes.tokenSisfreteAton = null;

      /** Mercado Livre */
      this.detalhes.authorizationML = null;
      this.renovarAutho = null;
        /** Produto enviado && Produtos Entregue */
      this.detalhes.message_notify_start = null;
      this.detalhes.message_notify_end = null;

      /** Shopee */
      this.detalhes.appSisfreteShopee = null;
      this.detalhes.appShopeeSellerCode = null;
      this.detalhes.appShopeeSellerShopId = null;
      this.detalhes.appShopeeErpCode = null;
      this.detalhes.appShopeeErpShopId = null;
      this.detalhes.appShopeeToken = null;

      this.saveDetalhes = false;
      this.saveCorreios = false;
      this.saveJad = false;
      this.saveProceda = false;
      this.saveBling = false;
      this.saveNuvem = false;
      this.saveAnyMarket = false;
      this.saveWake = false;
      this.saveFast = false;
      this.saveTray = false;
      this.saveAleph = false;
      this.saveIntLog = false;
      this.saveTiny = false;
      this.saveAton = false;
      this.saveMercadoLivre = false;
      this.saveShopee = false;
      this.saveEmail = false;
      this.saveSMS = false;
      this.saveNotifyBling = false;
      this.saveNotifyNuvem = false;
      this.saveNotifyAnyMarket = false;
      this.saveNotifyWake = false;
      this.saveNotifyFast = false;
      this.saveNotifyTray = false;
      this.saveNotifyAleph = false;
      this.saveNotifyIntLog = false;
      this.saveNotifyMercadoLivre = false;
      this.saveNotifyShopee = false;

      this.showConfig = false;
      this.showIntegradoresLog = false;
      this.showintegradoras = false;
      this.showServicos = false;

      this.modal.detalhes = false;
    },

    selectModoEnvio() {
      if(this.titleConfig == 'Wake') {
        return this.btnSaveNotifyWake();
      }else if(this.titleConfig == 'Tray') {
        return this.btnSaveNotifyTray();
      } else {
        return this.btnSaveNotifyFast();
      }
    },
    async carregarEmpresas() {
      this.grid.empresas.carregando = true;
      let url = `${baseApiUrl}/empresas?page=${this.grid.empresas.paginaAtual}`;

      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${this.codigo_empresa}`;

      await axios.get(url)
        .then((res) => {
          this.grid.empresas.items = res.data.empresas.data;
          this.grid.empresas.totalRegistros = res.data.empresas.total;
        })
        .catch(() => {
          showError("Não foi possível concluir a solicitação");
        })
        .finally(() => { this.grid.empresas.carregando = false; });
    },

    alteraIntegracao(integradora) {
      this.IntBaixarPedidos = !this.IntBaixarPedidos;

      if(this.IntBaixarPedidos) {
        this.IntBaixarPedidos = false;
        this.$swal({
          title: 'Alerta',
          html: `Deseja realmente alterar a integradora de pedidos para ${integradora} ?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Concluir',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#4BB543',
          cancelButtonColor: '#d33',
          allowOutsideClick: false,
        })
        .then((res) => {
          if(res.isConfirmed) {
            this.IntBaixarPedidos = true;
            if(integradora == 'Bling') {
              this.detalhes.integracao = 2;
              this.title = 'Bling';
              this.saveNotifyBling = true;
              this.notifications.desserts[this.indice].type = 1;
            } else if(integradora == 'Fast Commerce') {
              this.detalhes.integracao = 3;
              this.title = 'Fast Commerce';
              this.saveNotifyFast = true;
              this.notifications.desserts[this.indice].type = 1;
            } else if(integradora == 'AnyMarket') {
              this.detalhes.integracao = 7;
              this.title = 'AnyMarket'
              this.saveNotifyAnyMarket = true;
              this.notifications.desserts[this.indice].type = 1;
            } else if(integradora == 'Tray') {
              this.detalhes.integracao = 5;
              this.title = 'Tray';
              this.saveNotifyTray = true;
              this.notifications.desserts[this.indice].type = 1;
            } else if(integradora == 'Integrador Logístico Sisfrete') {
              this.detalhes.integracao = 8;
              this.title = 'Integrador Logístico Sisfrete';
              this.saveNotifyIntLog = true;
              this.notifications.desserts[this.indice].type = 1;
            } else if(integradora == 'Tiny') {
              this.detalhes.integracao = 9;
              this.title = 'Tiny';
              this.saveNotifyTiny = true;
              this.notifications.desserts[this.indice].type = 1;
            } else if(integradora == 'Wake') {
              this.detalhes.integracao = 10;
              this.title = 'Wake';
              this.saveNotifyWake = true;
              this.notifications.desserts[this.indice].type = 1;
            } else if(integradora == 'Aton') {
              this.detalhes.integracao = 11;
              this.title = 'Aton';
              this.saveAton = true;
            }
          } else {
            this.setValueCheck();
          }
        });
      } else {
        this.detalhes.integracao = 0;
      }
    },

    cancelarIntegracao(integradora) {
      this.$swal({
        title: 'Alerta',
        html: `Deseja realmente cancelar a integração com ${integradora} ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then((res) => {
        if(res.isConfirmed) {
          if(integradora == 'Bling') {
            this.detalhes.appSisfreteBlingV3 = null;
            if(this.detalhes.integracao == 2) {
              this.detalhes.integracao = 0;
            }
            if(this.detalhes.notifications) {
              for(let i = 0; i < this.notifications.desserts.length; i++) {
                if(this.notifications.desserts[i].channel == 'Bling') {
                  this.notifications.desserts[i].timeline.selected = [];
                  this.notifications.desserts[i].timeline.items = this.timeline.items;
                }
              }
            }
          } else if(integradora == 'Tray') {
            this.detalhes.appSisfreteTray = 0;
            this.detalhes.appTray_code = null;
            this.detalhes.appTray_api_address = null;
            this.detalhes.appTray_token = null;

            if(this.detalhes.notifications) {
              for(let i = 0; i < this.notifications.desserts.length; i++) {
                if(this.notifications.desserts[i].channel == 'TRAY') {
                  this.notifications.desserts[i].timeline.selected = [];
                  this.notifications.desserts[i].timeline.items = this.timeline.items;
                }
              }
            }
          } else if(integradora == 'Mercado Livre') {
            this.detalhes.appSisfreteML = 0;
            this.detalhes.authorizationML = null;

            if(this.detalhes.notifications) {
              for(let i = 0; i < this.notifications.desserts.length; i++) {
                if(this.notifications.desserts[i].channel == 'Mercado Livre') {
                  this.notifications.desserts[i].timeline.selected = [];
                  this.notifications.desserts[i].timeline.items = this.timeline.items;
                }
              }
            }
          } else if(integradora == 'Shopee') {
            this.detalhes.appSisfreteShopee = 0;
            this.detalhes.appShopeeSellerCode = null;
            this.detalhes.appShopeeSellerShopId = null;
            this.detalhes.appShopeeErpCode = null;
            this.detalhes.appShopeeErpShopId = null;
            this.detalhes.appShopeeToken = null;
            this.appShopeeShopId = null;

            if(this.detalhes.notifications) {
              for(let i = 0; i < this.notifications.desserts.length; i++) {
                if(this.notifications.desserts[i].channel == 'Shopee') {
                  this.notifications.desserts[i].timeline.selected = [];
                  this.notifications.desserts[i].timeline.items = this.timeline.items;
                }
              }
            }
          }
          this.saveDetalhes = true;
          this.alert = true;
        }
      })
    },

    setValueCheck() {
      if(this.detalhes.integracao == 2 && this.titleConfig == 'Bling') {
        this.IntBaixarPedidos = true;
        return true;
      } else if(this.detalhes.integracao == 3 && this.titleConfig == 'Fast Commerce') {
        this.IntBaixarPedidos = true;
        return true;
      } else if(this.detalhes.integracao == 7 && this.titleConfig == 'AnyMarket') {
        this.IntBaixarPedidos = true;
        return true;
      } else if(this.detalhes.integracao == 5 && this.titleConfig == 'Tray') {
        this.IntBaixarPedidos = true;
        return true;
      } else if(this.detalhes.integracao == 8 && this.titleConfig == 'Integrador Logístico Sisfrete') {
        this.IntBaixarPedidos = true;
        return true;
      } else if(this.detalhes.integracao == 9 && this.titleConfig == 'Tiny') {
        this.IntBaixarPedidos = true;
        return true;
      } else if(this.detalhes.integracao == 10 && this.titleConfig == 'Wake') {
        this.IntBaixarPedidos = true;
        return true;
      } else if(this.detalhes.integracao == 11 && this.titleConfig == 'Aton') {
        this.IntBaixarPedidos = true;
        return true;
      } else {
        this.IntBaixarPedidos = false;
        return false;
      }
    },

    async carregarTokenSisfrete() {
      await axios
        .get(`${baseApiUrlPersys}/chaves/${this.codigo_empresa}`)
        .then((res) => {
          this.detalhes.rastreioTokenSisfrete = res.data.tokenApi;
        })
        .catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        }
      );
    },

    async carregarIntegradoras() {
      let url = `${baseApiUrl}/integradores`;

      await axios.get(url)
        .then((res) => {
          this.integradores = res.data.dados;
        });
    },

    /**
     * Quando clica na lupa para carregar os detalhes da empresa
     */
    async carregarDetalhes(item) {
      this.empresaAtual = `${item.codigo} - ${item.razao_social}`;
      this.modal.detalhes = true;
      let url = `${baseApiUrl}/empresas-detalhes?codigo=${item.codigo}&type=detalhes`;

      await axios.get(url)
        .then((res) => {
          this.detalhes.codigo = res.data.empresas.data[0].codigo;
          this.detalhes.cnpj = res.data.empresas.data[0].cnpj;
          this.detalhes.razao_social = res.data.empresas.data[0].razao_social;
          this.detalhes.nome_fantasia = res.data.empresas.data[0].nome_fantasia;
          this.detalhes.telefone = res.data.empresas.data[0].telefone;
          this.detalhes.email = res.data.empresas.data[0].email;
          this.detalhes.contratocorreios = res.data.empresas.data[0].contratocorreios;
          this.detalhes.cartaocorreios = res.data.empresas.data[0].cartaocorreios;
          this.detalhes.rastreioCorreios = res.data.empresas.data[0].rastreioCorreios;
          this.detalhes.emailcorreios = res.data.empresas.data[0].emailcorreios == null ? "" : res.data.empresas.data[0].emailcorreios;
          this.detalhes.senhacorreios = res.data.empresas.data[0].senhacorreios == null ? "" : res.data.empresas.data[0].senhacorreios;
          this.detalhes.tokencorreios = res.data.empresas.data[0].tokencorreios == null ? "" : res.data.empresas.data[0].tokencorreios;
          this.detalhes.rastreioJad = res.data.empresas.data[0].rastreioJad;
          this.detalhes.tokenjad = res.data.empresas.data[0].tokenjad == null ? "" : res.data.empresas.data[0].tokenjad;
          this.detalhes.rastreioJad_tipo = res.data.empresas.data[0].rastreioJad_tipo;
          this.detalhes.rastreioAlephcrm = res.data.empresas.data[0].rastreioAlephcrm;
          this.detalhes.productAlephcrm = res.data.empresas.data[0].productAlephcrm;
          this.detalhes.pedidosFastCommerce = res.data.empresas.data[0].pedidosFastCommerce;
          this.detalhes.apikey = res.data.empresas.data[0].apikey == null ? "" : res.data.empresas.data[0].apikey;
          this.detalhes.accountid = res.data.empresas.data[0].accountid == null ? "" : res.data.empresas.data[0].accountid;
          this.detalhes.ftpProceda = res.data.empresas.data[0].ftpProceda;
          this.detalhes.apeliFtpProceda = res.data.empresas.data[0].apelidoFtp;
          this.detalhes.integracao = res.data.empresas.data[0].integracao;
          this.detalhes.rastreioBling = res.data.empresas.data[0].rastreioBling;
          this.detalhes.appSisfreteBlingV3 = res.data.empresas.data[0].appSisfreteBlingV3;
          this.rastreioCorreios = this.detalhes.rastreioCorreios;
          this.rastreioJad = this.detalhes.rastreioJad;
          this.rastreioAlephcrm = this.detalhes.rastreioAlephcrm;
          if (res.data.empresas.data[0].rastreioFastCommerce) {
            let fieldsFastCommerce = JSON.parse(res.data.empresas.data[0].rastreioFastCommerce);
            this.detalhes.FastCommerce.StoreId = fieldsFastCommerce.StoreId;
            this.detalhes.FastCommerce.StoreName = fieldsFastCommerce.StoreName;
            this.detalhes.FastCommerce.Username = fieldsFastCommerce.Username;
            this.detalhes.FastCommerce.Password = fieldsFastCommerce.Password;
          }
          this.detalhes.appSisfreteML = res.data.empresas.data[0].appSisfreteML;
          this.detalhes.authorizationML = res.data.empresas.data[0].authorizationML;
          this.detalhes.notifications = res.data.empresas.data[0].notifications;
          this.detalhes.appSisfreteNS = res.data.empresas.data[0].appSisfreteNS;
          this.detalhes.urlNuvemShop = res.data.empresas.data[0].urlNuvemShop;
          this.urlNuvemShop = res.data.empresas.data[0].urlNuvemShop == null ? "" : res.data.empresas.data[0].urlNuvemShop;
          this.detalhes.userIdNS = res.data.empresas.data[0].userIdNS;
          this.detalhes.tokenSisfreteNS = res.data.empresas.data[0].tokenSisfreteNS == null ? "" : res.data.empresas.data[0].tokenSisfreteNS;
          this.detalhes.enviosSisfreteNS = res.data.empresas.data[0].enviosSisfreteNS == null ? 0 : res.data.empresas.data[0].enviosSisfreteNS;
          this.detalhes.opcaoSisfreteNS = res.data.empresas.data[0].opcaoSisfreteNS == null ? 0 : res.data.empresas.data[0].opcaoSisfreteNS;
          this.detalhes.modoSisfreteNS = res.data.empresas.data[0].modoSisfreteNS;
          this.detalhes.appSisfreteTray = res.data.empresas.data[0].appSisfreteTray;
          this.detalhes.appTray_code = res.data.empresas.data[0].appTray_code;
          this.detalhes.appTray_api_address = res.data.empresas.data[0].appTray_api_address;
          this.detalhes.appTray_token = res.data.empresas.data[0].appTray_token;
          this.detalhes.appSisfreteAny = res.data.empresas.data[0].appSisfreteAny;
          this.detalhes.apptokenAny = res.data.empresas.data[0].apptokenAny;
          this.detalhes.appoiAny = res.data.empresas.data[0].appoiAny;
          this.detalhes.modoSisfreteAny = res.data.empresas.data[0].modoSisfreteAny;
          this.detalhes.appSisfreteWake = res.data.empresas.data[0].appSisfreteWake;
          this.detalhes.apptokenWake = res.data.empresas.data[0].apptokenWake;
          this.detalhes.modoSisfreteWake = res.data.empresas.data[0].modoSisfreteWake;
          if (res.data.empresas.data[0].notifications) {
            this.detalhes.notifications = true;
            this.loadNotify();
          }
          this.detalhes.image = res.data.empresas.data[0].image;
          this.detalhes.message_notify_start = res.data.empresas.data[0].message_notify_start;
          this.detalhes.message_notify_end = res.data.empresas.data[0].message_notify_end;
          this.detalhes.message_notify_sms = res.data.empresas.data[0].message_notify_sms;
          this.renovarAutho = res.data.empresas.data[0].renovarAutho == null ? 0 : res.data.empresas.data[0].renovarAutho;

          this.detalhes.appTray_status_pedidos = [];
          let appTray_status_pedidos = res.data.empresas.data[0].appTray_status_pedidos == null ? [] : JSON.parse(res.data.empresas.data[0].appTray_status_pedidos);
          appTray_status_pedidos.forEach(element => {
            this.detalhes.appTray_status_pedidos.push({ id: element.id, status: element.status });
          });

          this.detalhes.appstatuspedidosWake = [];
          let appstatuspedidosWake = res.data.empresas.data[0].appstatuspedidosWake == null ? [] : JSON.parse(res.data.empresas.data[0].appstatuspedidosWake);
          appstatuspedidosWake.forEach(element => {
            this.detalhes.appstatuspedidosWake.push({ id: element.id, status: element.status });
          });

          this.detalhes.appSisfreteTiny = res.data.empresas.data[0].appSisfreteTiny;
          this.detalhes.tokenSisfreteTiny = res.data.empresas.data[0].tokenSisfreteTiny;

          this.detalhes.appSisfreteAton = res.data.empresas.data[0].appSisfreteAton;
          this.detalhes.tokenSisfreteAton = res.data.empresas.data[0].tokenSisfreteAton;

          this.detalhes.appSisfreteShopee = res.data.empresas.data[0].appSisfreteShopee;
          this.detalhes.appShopeeSellerCode = res.data.empresas.data[0].appShopeeSellerCode;
          this.detalhes.appShopeeSellerShopId = res.data.empresas.data[0].appShopeeSellerShopId;
          this.detalhes.appShopeeErpCode = res.data.empresas.data[0].appShopeeErpCode;
          this.detalhes.appShopeeErpShopId = res.data.empresas.data[0].appShopeeErpShopId;
          this.detalhes.appShopeeToken = res.data.empresas.data[0].appShopeeToken;
          this.appShopeeShopId = res.data.empresas.data[0].appShopeeSellerShopId ?? res.data.empresas.data[0].appShopeeErpShopId;

          this.detalhes.pedidosIntLog = res.data.empresas.data[0].pedidosIntLog;
          this.detalhes.apikeyIntLog = res.data.empresas.data[0].apikeyIntLog;

          this.detalhes_edit.codigo = this.detalhes.codigo;
          this.detalhes_edit.cnpj = this.detalhes.cnpj;
          this.detalhes_edit.razao_social = this.detalhes.razao_social;
          this.detalhes_edit.nome_fantasia = this.detalhes.nome_fantasia;
          this.detalhes_edit.telefone = this.detalhes.telefone;
          this.detalhes_edit.email = this.detalhes.email;
          this.detalhes_edit.contratocorreios = this.detalhes.contratocorreios;
          this.detalhes_edit.cartaocorreios = this.detalhes.cartaocorreios;
          this.detalhes_edit.rastreioCorreios = this.detalhes.rastreioCorreios;
          this.detalhes_edit.emailcorreios = this.detalhes.emailcorreios;
          this.detalhes_edit.senhacorreios = this.detalhes.senhacorreios;
          this.detalhes_edit.tokencorreios = this.detalhes.tokencorreios;
          this.detalhes_edit.rastreioJad = this.detalhes.rastreioJad;
          this.detalhes_edit.tokenjad = this.detalhes.tokenjad;
          this.detalhes_edit.rastreioJad_tipo = this.detalhes.rastreioJad_tipo;
          this.detalhes_edit.rastreioAlephcrm = this.detalhes.rastreioAlephcrm;
          this.detalhes_edit.productAlephcrm = this.detalhes.productAlephcrm;
          this.detalhes_edit.pedidosFastCommerce = this.detalhes.pedidosFastCommerce;
          this.detalhes_edit.apikey = this.detalhes.apikey;
          this.detalhes_edit.accountid = this.detalhes.accountid;
          this.detalhes_edit.ftpProceda = this.detalhes.ftpProceda;
          this.detalhes_edit.apeliFtpProceda = this.detalhes.apeliFtpProceda;
          this.detalhes_edit.integracao = this.detalhes.integracao;
          this.detalhes_edit.rastreioBling = this.detalhes.rastreioBling;
          this.detalhes_edit.appSisfreteBlingV3 = this.detalhes.appSisfreteBlingV3;

          this.detalhes_edit.FastCommerce.StoreId = this.detalhes.FastCommerce.StoreId;
          this.detalhes_edit.FastCommerce.StoreName = this.detalhes.FastCommerce.StoreName;
          this.detalhes_edit.FastCommerce.Username = this.detalhes.FastCommerce.Username;
          this.detalhes_edit.FastCommerce.Password = this.detalhes.FastCommerce.Password;

          this.detalhes_edit.appSisfreteML = this.detalhes.appSisfreteML;
          this.detalhes_edit.authorizationML = this.detalhes.authorizationML;
          this.detalhes_edit.notifications = this.detalhes.notifications;
          this.detalhes_edit.appSisfreteNS = this.detalhes.appSisfreteNS;
          this.detalhes_edit.urlNuvemShop = this.detalhes.urlNuvemShop;
          this.detalhes_edit.userIdNS = this.detalhes.userIdNS;
          this.detalhes_edit.tokenSisfreteNS = this.detalhes.tokenSisfreteNS;
          this.detalhes_edit.enviosSisfreteNS = this.detalhes.enviosSisfreteNS;
          this.detalhes_edit.opcaoSisfreteNS = this.detalhes.opcaoSisfreteNS;
          this.detalhes_edit.modoSisfreteNS = this.detalhes.modoSisfreteNS;
          this.detalhes_edit.appSisfreteTray = this.detalhes.appSisfreteTray;
          this.detalhes_edit.appTray_code = this.detalhes.appTray_code;
          this.detalhes_edit.appTray_api_address = this.detalhes.appTray_api_address;
          this.detalhes_edit.appTray_token = this.detalhes.appTray_token;
          this.detalhes_edit.appSisfreteAny = this.detalhes.appSisfreteAny;
          this.detalhes_edit.apptokenAny = this.detalhes.apptokenAny;
          this.detalhes_edit.appoiAny = this.detalhes.appoiAny;
          this.detalhes_edit.modoSisfreteAny = this.detalhes.modoSisfreteAny;
          this.detalhes_edit.appSisfreteWake = this.detalhes.appSisfreteWake;
          this.detalhes_edit.apptokenWake = this.detalhes.apptokenWake;
          this.detalhes_edit.modoSisfreteWake = this.detalhes.modoSisfreteWake;

          this.detalhes_edit.image = this.detalhes.image;
          this.detalhes_edit.message_notify_start = this.detalhes.message_notify_start;
          this.detalhes_edit.message_notify_end = this.detalhes.message_notify_end;
          this.detalhes_edit.message_notify_sms = this.detalhes.message_notify_sms;

          this.detalhes_edit.appTray_status_pedidos = this.detalhes.appTray_status_pedidos;

          this.detalhes_edit.appstatuspedidosWake = this.detalhes_edit.appstatuspedidosWake;

          this.detalhes_edit.appSisfreteShopee = this.detalhes.appSisfreteShopee;
          this.detalhes_edit.appShopeeSellerCode = this.detalhes.appShopeeSellerCode;
          this.detalhes_edit.appShopeeSellerShopId = this.detalhes.appShopeeSellerShopId;
          this.detalhes_edit.appShopeeErpCode = this.detalhes.appShopeeErpCode;
          this.detalhes_edit.appShopeeErpShopId = this.detalhes.appShopeeErpShopId;
          this.detalhes_edit.appShopeeToken = this.detalhes.appShopeeToken;
          this.appShopeeShopId_edit = this.appShopeeShopId;

          this.detalhes_edit.appSisfreteTiny = this.detalhes.appSisfreteTiny;
          this.detalhes_edit.tokenSisfreteTiny = this.detalhes.tokenSisfreteTiny;

          this.detalhes_edit.appSisfreteAton = this.detalhes.appSisfreteAton;
          this.detalhes_edit.tokenSisfreteAton = this.detalhes.tokenSisfreteAton;

          this.detalhes_edit.pedidosIntLog = this.detalhes.pedidosIntLog;
          this.detalhes_edit.apikeyIntLog = this.detalhes.apikeyIntLog;
        })
        .catch(() => {
          showError("Não foi possível concluir a solicitação!");
        });
    },

    /**
     * Determina se irá mostrar as notificações
     * Um laço de repetição que percorre a lista de notificações e procura se
     * existe alguma notificação para a integradora específica
     */
    showNotify() {
      if(!this.detalhes.notifications || this.titleConfig == 'Aton' || this.titleConfig == 'Integrador Logístico Sisfrete') {
        return false;
      }

      if(this.titleConfig == 'SMS') {
        return true;
      }

      let show = false;
      for(let i = 0; i < this.notifications.desserts.length; i ++){
        if(this.notifications.desserts[i].channel == "Bling" && this.titleConfig == 'Bling') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;

          return true;
        }else if(this.notifications.desserts[i].channel == "NUVEMSHOP" && this.titleConfig == 'NuvemShop' ) {
            this.tipo_notify = this.notifications.desserts[i].type;
            this.indice = i;
            show = true;
          return true;
        }else if(this.notifications.desserts[i].channel == 'AnyMarket' && this.titleConfig == 'AnyMarket') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;
          return true;
        }else if(this.notifications.desserts[i].channel == 'WAKE' && this.titleConfig == 'Wake') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;
          this.selectModoNotify = this.tipo_notify;
          return true;
        }else if(this.notifications.desserts[i].channel == 'TRAY' && this.titleConfig == 'Tray') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          this.selectModoNotify = this.tipo_notify;
          show = true;
          return true;
        }else if(this.notifications.desserts[i].channel == 'ALEPH CRM' && this.titleConfig == 'Aleph CRM') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;
          return true;
        }else if(this.notifications.desserts[i].channel == 'INTEGRADOR LOGÍSTICO' && this.titleConfig == 'Integrador Logístico Sisfrete' ) {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;
          return true;
        } else if(this.notifications.desserts[i].channel == 'TINY' && this.titleConfig == 'Tiny'){
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;
          return true;
        } else if(this.notifications.desserts[i].channel == 'Mercado Livre' && this.titleConfig == 'Mercado Livre') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;
          return true;
        }else if(this.notifications.desserts[i].channel == 'Shopee' && this.titleConfig == 'Shopee') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          show = true;
          return true;
        } else if(this.notifications.desserts[i].channel == 'Fast Commerce' && this.titleConfig == 'Fast Commerce') {
          this.tipo_notify = this.notifications.desserts[i].type;
          this.indice = i;
          this.selectModoNotify = this.tipo_notify;
          show = true;
          return true;
        }
      }

      if(show == false) {
        if(this.titleConfig == 'Bling') {
          let showF = false;
          for(let x = 0; x < this.notifications.desserts.length; x++) {
            if(this.notifications.desserts[x].channel == "Bling") {
              show = true;
            }
          }

          if (!showF) {
            this.notifications.desserts.push({ channel: "Bling", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
            this.indice = (this.notifications.desserts.length - 1);
            this.tipo_notify = this.notifications.desserts[this.indice].type;
          }
        } else if(this.titleConfig == 'NuvemShop') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "NUVEMSHOP") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "NUVEMSHOP", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
            }
        } else if(this.titleConfig == 'AnyMarket') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "AnyMarket") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "AnyMarket", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
            }
        } else if(this.titleConfig == 'Wake') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "WAKE") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "WAKE", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
              if(this.notifications.desserts[this.indice].type == 'Todos Pedidos'){
                this.notifications.desserts[this.indice].type = 1;
              } else if(this.notifications.desserts[this.indice].type == 'Somente') {
                this.notifications.desserts[this.indice].type = 0;
              }
              this.selectModoNotify = this.tipo_notify;

            }
        } else if(this.titleConfig == 'Tray') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "TRAY") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "TRAY", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
              if(this.notifications.desserts[this.indice].type == 'Todos Pedidos'){
                this.notifications.desserts[this.indice].type = 1;
              } else if(this.notifications.desserts[this.indice].type == 'Somente') {
                this.notifications.desserts[this.indice].type = 0;
              }
              this.selectModoNotify = this.tipo_notify;
            }
        } else if(this.titleConfig == 'Aleph CRM') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "ALEPH CRM") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "ALEPH CRM", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
            }
        } else if(this.titleConfig == 'Integrador Logístico Sisfrete') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "INTEGRADOR LOGÍSTICO") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "INTEGRADOR LOGÍSTICO", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
            }
        } else if(this.titleConfig == 'Tiny') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "TINY") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "TINY", type: "Todos Pedidos", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
            }
        } else if(this.titleConfig == 'Mercado Livre') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "Mercado Livre") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "Mercado Livre", type: "Somente", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
            }
        } else if(this.titleConfig == 'Shopee') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "Shopee") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "Shopee", type: "Somente", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
              if(this.tipo_notify == 0) {
                this.tipo_notify = "Somente";
              } else if(this.tipo_notify == 1) {
                this.tipo_notify = "Todos Pedidos";
              }
            }
        } else if(this.titleConfig == 'Fast Commerce') {
          let showF = false;
            for(let x = 0; x < this.notifications.desserts.length; x++) {
              if(this.notifications.desserts[x].channel == "Fast Commerce") {
                showF = true;
              }
            }

            if (!showF) {
              this.notifications.desserts.push({ channel: "Fast Commerce", type: "Somente", timeline: { selected: [], items: this.timeline.items } });
              this.indice = (this.notifications.desserts.length - 1);
              this.tipo_notify = this.notifications.desserts[this.indice].type;
              if(this.notifications.desserts[this.indice].type == 'Todos Pedidos'){
                this.notifications.desserts[this.indice].type = 1;
              } else if(this.notifications.desserts[this.indice].type == 'Somente') {
                this.notifications.desserts[this.indice].type = 0;
              }
              this.selectModoNotify = this.tipo_notify;
            }
        }
      }
    },

    /**
     * Carrega as notificações
     */
    async loadNotify() {
      let url = `${baseApiUrl}/list-notify?cod_empresa=${this.codigo_empresa}`;
      let channel = '';
      let timeline = { selected: [] }
      await axios
        .get(url)
        .then((res) => {
          if (res.data.dados) {
            this.notifica = [];
            this.notifications.desserts = [];
            this.notifications_edit.desserts = [];
            res.data.dados.forEach(element => {
              if (element.Canal) {
                channel = element.Canal
              } else {
                channel = element.nome
              }
              this.notifications.desserts.push({ channel: channel, type: element.Como, timeline: { selected: [], items: this.timeline.items } })
              this.notifica.push(channel);
              this.notifications_edit.desserts.push({ channel: channel, type: element.Como, timeline: { selected: [], items: this.timeline.items } })
            });

            if (res.data.notify) {
              this.notifications.selected = [];
              this.notifications_edit.selected = [];
              res.data.notify.forEach(element => {
                let type = 0;
                let timeline = element.timeline == null ? [] : element.timeline;
                this.notifications.desserts.forEach(eleSel => {
                  if (eleSel.channel === element.channel) {
                    eleSel.timeline.selected = timeline;
                    type = eleSel.Como == "Todos pedidos" ? 1 : 0
                  } else if(!this.notifica.includes(element.channel)) {
                    type = element.type;
                    this.notifications.desserts.push({ channel: element.channel, type: type,
                    timeline: { selected: element.timeline, items: this.timeline.items } });
                    this.notifica.push(element.channel);
                  }
                });
                this.notifications.selected.push({ channel: element.channel, type: type, timeline: timeline });

                this.notifications_edit.desserts.forEach(eleSel => {
                  if (eleSel.channel === element.channel) {
                    eleSel.timeline.selected = timeline;
                    type = eleSel.Como == "Todos pedidos" ? 1 : 0
                  }
                });
                this.notifications_edit.selected.push({ channel: element.channel, type: type, timeline: timeline });
              });
            }
          }
        });
    },

    /**
     * Carrega as cores das integradoras
     */
    getStyleIntegradoras(integradora) {
      let show = false;

      /** Verifica se a integradora está ativa através do token */
      if(integradora.nome == 'Bling' && this.detalhes.appSisfreteBlingV3 != '' && this.detalhes.appSisfreteBlingV3 != null) {
        show = true;
      } else if(integradora.nome == 'NuvemShop' && this.detalhes.tokenSisfreteNS != '' && this.detalhes.tokenSisfreteNS != null){
        show = true;
      } else if(integradora.nome == 'AnyMarket' && this.detalhes.apptokenAny != '' && this.detalhes.apptokenAny != null && this.detalhes.apptokenAny != 'null') {
        show = true;
      } else if(integradora.nome == 'Wake' && this.detalhes.apptokenWake != '' && this.detalhes.apptokenWake != null && this.detalhes.apptokenWake != 'null') {
        show = true;
      } else if(integradora.nome == 'Tray' && this.detalhes.appTray_token != '' && this.detalhes.appTray_token != null) {
        show = true;
      } else if(integradora.nome == 'Fast Commerce' && this.detalhes.FastCommerce.StoreId != null && this.detalhes.FastCommerce.StoreName != null &&
        this.detalhes.FastCommerce.Username != null && this.detalhes.FastCommerce.Password != null) {
        show = true;
      } else if(integradora.nome == 'Aleph CRM' && this.detalhes.accountid != '' && this.detalhes.apikey != '') {
        show = true;
      } else if(integradora.nome == 'Integrador Logístico Sisfrete' && this.detalhes.apikeyIntLog != '' && this.detalhes.apikeyIntLog != null) {
        show = true;
      } else if(integradora.nome == 'Tiny' && this.detalhes.tokenSisfreteTiny != '' && this.detalhes.tokenSisfreteTiny != null) {
        show = true;
      } else if(integradora.nome == 'Aton' && this.detalhes.tokenSisfreteAton != '' && this.detalhes.tokenSisfreteAton != null) {
        show = true;
      }

      /** Quando clica na integradora */
      if(this.showConfig && this.titleConfig == integradora.nome && show) {
        return 'background-color: rgba(0,0,0,20%); border: 3px solid #018656;'
      } else if(this.showConfig && !show && this.titleConfig == integradora.nome) {
        return 'background-color: rgba(0,0,0,20%);'
      } else if(show) {
        return 'background-color: #ebebeb; border: 3px solid #018656;'
      }
      /** Cor padrão da integradora caso ela não esteja ativa e não tenha sido selecionada */
      else {
        return 'background-color: #ebebeb'
      }
    },

    /**
     * Carrega as cores dos Canais de Venda
     * mesma regra das integradoras
     */
    getStyleCanais(canal) {
      let show = false;

      if(canal.nome == 'Mercado Livre' && this.detalhes.authorizationML != '' && this.detalhes.authorizationML != null) {
        show = true;
      } else if(canal.nome == 'Shopee' && this.detalhes.appShopeeToken != '' && this.detalhes.appShopeeToken != null) {
        show = true;
      }

      if(this.showConfig && this.titleConfig == canal.nome && show) {
        return 'background-color: rgba(0,0,0,25%); border: 3px solid #018656;'
      }else if(this.showConfig && !show && this.titleConfig == canal.nome) {
        return 'background-color: rgba(0,0,0,20%);';
      } else if(show) {
        return 'background-color: #ebebeb; border: 3px solid #018656;'
      } else {
        return 'background-color: #ebebeb;'
      }
    },

    async generateToken() {
      this.detalhes.rastreioTokenSisfrete = await this.stringRandomica();
      this.saveDetalhes = true;
      await this.saveToken();
    },

    confirmDeleteToken() {
      this.$swal({
        title: 'Alerta',
        html: `Deseja realmente excluir o Token Sisfrete ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.detalhes.rastreioTokenSisfrete = '';
          this.saveToken();
        }
      });
    },

    async saveToken() {
      await axios
        .post(`${baseApiUrlPersys}/chaves`, {
          codigoEmpresa: this.codigo_empresa,
          tokenAPI: this.detalhes.rastreioTokenSisfrete,
        })
        .then(() => {
          this.$swal({
            title: 'Sucesso!!!',
            text: 'Sisfrete Token salvo com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        });
    },

    async generateTokenBling() {
      let state = await this.stringRandomica(26);
      state += this.codigo_empresa;
      let url = `${baseApiUrl}/AppSisfreteBlingV3`;
      url += `?state=${state}`;
      const width = 700;
      const height = 600;
      const left = (screen.width - width) / 2;
      const top = (screen.height - height) / 4;
      const windowFeatures = `left=${left},top=${top},width=${width},height=${height}`;
      this.windowNew.result = window.open(url, 'AppSisfreteBlingV3', windowFeatures);

      this.saveDetalhes = true;
      this.alert = true;
    },

    async authorizationML() {
      let state = await this.stringRandomica(26);
      state += this.codigo_empresa;
      let url = this.windowNew.url;
      url += `&state=${state}`;
      this.windowNew.result = window.open(url, this.windowNew.name);

      this.saveDetalhes = true;
      this.alert = true;
    },

    authorizationNS(sendUpdate) {
      if ( sendUpdate.nuvemShop.url_loja != "" ) {
        sendUpdate.nuvemShop.url_loja = sendUpdate.nuvemShop.url_loja.replace('https://', '')
        sendUpdate.nuvemShop.url_loja = sendUpdate.nuvemShop.url_loja.replace('/', '')
        this.saveDetailsAlert(sendUpdate);

        let url = `http://${sendUpdate.nuvemShop.url_loja}/admin/apps/1356/authorize/`;
        this.windowNew.result = window.open(url, 'AppSisfreteNuvemShop')

        this.saveDetalhes = false;
      }
    },

    async urlConsulta(typelink) {
      let url = `${baseApiUrl}/authshopee`;
      let link = typelink == 1 ? "Sel" : "Erp";
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&link=${link}`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.retorno) {
            this.strUrlShopee = `${response.data.dados}`;
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Que pena!!!',
            text: 'Não foi possível concluir!!!',
            icon: `${error}`,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => {
          this.urlShopee = true;
        });
    },

    async urlOpen() {
      window.open(this.strUrlShopee, '_blank', 'noreferrer');
      this.urlShopee = false;

      this.saveShopee = false;
    },

    async fnStatusPedidosTray() {
      this.statuspedidostray.conteudo = [];
      this.showStatusPedidosTray = true;
      this.statuspedidostray.carregando = true;
      await axios
        .get(`${baseApiUrl}/statusTray/${this.codigo_empresa}`)
        .then((res) => {
          if (res.data.retorno) {
            this.statuspedidostray.conteudo = res.data.statusTray;
          }
        })
        .finally(() => {
          this.statuspedidostray.carregando = false;
        });
    },

    async fnStatusPedidosWake() {
      this.statuspedidosWake.conteudo = [];
      this.framePedidosWake = true;
      this.statuspedidosWake.carregando = true;
      await axios
        .get(`${baseApiUrl}/statusWake/${this.codigo_empresa}`)
        .then((res) => {
          if (res.data.retorno) {
            this.statuspedidosWake.conteudo = res.data.statusWake;
          }
        })
        .finally(() => {
          this.statuspedidosWake.carregando = false;
        });
    },

    async fnRegistrarToken(codeChannel) {

      this.frameRegistrarToken = true;
      this.canaisToken.carregando = true;
      await axios
        .get(`${baseApiUrl}/statusChannel/${this.codigo_empresa}/${codeChannel}`)
        .then((res) => {
          if (res.data.retorno) {
            this.canaisToken.conteudo = res.data.dados;
            this.canaisToken.conteudo.forEach(element => {
              // if (element.id == Number(this.detalhes.shopee.token_shopee.substr(0, 4))) {
              if (element.id == Number(this.detalhes.appShopeeToken.substr(0, 4))) {
                this.canaisToken.codigo = [element];
              }
            });
          }
        })
        .finally(() => {
          this.canaisToken.carregando = false;
        });
    },

    async saveRegistrarToken() {
      let idToken = 0;

      this.canaisToken.conteudo.forEach(element => {
        if (element.id == this.canaisToken.codigo[0].id) {
          // this.detalhes.shopee.token_shopee = `${this.canaisToken.codigo[0].id} - ${this.canaisToken.codigo[0].nome}`
          this.detalhes.appShopeeToken = `${this.canaisToken.codigo[0].id} - ${this.canaisToken.codigo[0].nome}`
          idToken = `${this.canaisToken.codigo[0].id}`
        }
      });
      if (idToken) {
        this.vincularSeller(idToken);
        this.saveDetailsAlert(this.detalhes);
        this.frameRegistrarToken = false;
      }
    },

    async vincularSeller(tokenId) {
      await axios
        .post(`${baseApiUrl}/linkChannel/${this.codigo_empresa}/${tokenId}`, { data: this.appShopeeShopId })
        .then(() => {});
    },

    async generateApiKey() {
      this.detalhes.int_log.api = await this.stringRandomica();
      await this.saveDetailsAlert(this.detalhes);
    },

    saveDetails(formUpdate) {
      let show = true;
      if(this.detalhes.message_notify_sms != null && this.detalhes.message_notify_sms != ''){
        if(this.detalhes.message_notify_sms.length > 160){
          this.$swal({
            title: 'Erro',
            text: 'O campo de mensagem SMS possui caracteres além do seu limite!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          show = false;
        }
      }

      if(show) {
        let retSend = this.saveForm(formUpdate);
        if (retSend) {
          showSuccess("Campos Gravados com Sucesso!");
        } else if (retSend == false) {
          this.$swal({
            title: 'Que pena!!!',
            text: `${response.data.mensagem}`,
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        } else if (retSend == -1) {
          this.$swal({
            title: 'Desculpe!!!',
            text: 'Não foi possível concluir!!!',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        }
        this.saveDetalhes = false;
        this.saveCorreios = false;
        this.saveJad = false;
        this.saveProceda = false;
        this.saveBling = false;
        this.saveNuvem = false;
        this.saveAnyMarket = false;
        this.saveWake = false;
        this.saveFast = false;
        this.saveTray = false;
        this.saveAleph = false;
        this.saveIntLog = false;
        this.saveMercadoLivre = false;
        this.saveShopee = false;
        this.saveEmail = false;
        this.saveSMS = false;
        this.saveNotifyBling = false;
        this.saveNotifyNuvem = false;
        this.saveNotifyAnyMarket = false;
        this.saveNotifyWake = false;
        this.saveNotifyTray = false;
        this.saveNotifyAleph = false;
        this.saveNotifyTiny = false;
        this.saveNotifyMercadoLivre = false;
        this.saveNotifyShopee = false;
        this.saveNotifyFast = false;
      }
    },

    saveDetailsAlert(formUpdate) {
      this.$swal({
        title: 'Alerta',
        html: `Salvar as alterações ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Concluir',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.saveDetails(formUpdate);
          } else if(this.alert && this.titleConfig != '' && this.titleConfig != null){
            this.limpar(this.titleConfig);
          }
        });
    },

    limpar(integradora) {
      if(integradora == 'Correios') {
        this.detalhes.contratocorreios = this.detalhes_edit.contratocorreios;
        this.detalhes.cartaocorreios = this.detalhes_edit.cartaocorreios;
        this.detalhes.rastreioCorreios = this.detalhes_edit.rastreioCorreios;
        this.detalhes.emailcorreios = this.detalhes_edit.emailcorreios;
        this.detalhes.senhacorreios = this.detalhes_edit.senhacorreios;
        this.detalhes.tokencorreios = this.detalhes_edit.tokencorreios;

        this.saveCorreios = false;
      } else if(integradora == 'JadLog') {
        this.detalhes.tokenjad = this.detalhes_edit.tokenjad;
        this.detalhes.rastreioJad_tipo = this.detalhes_edit.rastreioJad_tipo;

        this.saveJad = false;
      } else if(integradora == 'Proceda') {
        this.detalhes.apeliFtpProceda = this.detalhes_edit.apeliFtpProceda;

        this.saveProceda = false;
      } else if(integradora == 'Bling') {
        this.detalhes.appSisfreteBlingV3 = this.detalhes_edit.appSisfreteBlingV3;
        if(this.detalhes_edit.integracao == 2) {
          this.detalhes.integracao = 2;
        }

        this.saveBling = false;
      } else if(integradora == 'notifyBling') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;
        this.saveNotifyBling = false;
      } else if(integradora == 'NuvemShop') {
        this.detalhes.urlNuvemShop = this.detalhes_edit.urlNuvemShop;
        this.detalhes.userIdNS = this.detalhes_edit.userIdNS;
        this.detalhes.tokenSisfreteNS = this.detalhes_edit.tokenSisfreteNS;
        this.detalhes.enviosSisfreteNS = this.detalhes_edit.enviosSisfreteNS;
        this.detalhes.opcaoSisfreteNS = this.detalhes_edit.opcaoSisfreteNS;
        this.detalhes.modoSisfreteNS = this.detalhes_edit.modoSisfreteNS;

        this.saveNuvem = false;
      } else if(integradora == 'notifyNuvem') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;
        this.saveNotifyNuvem = false;
      } else if(integradora == 'AnyMarket') {
        this.detalhes.apptokenAny = this.detalhes_edit.apptokenAny;
        this.detalhes.appoiAny = this.detalhes_edit.appoiAny;
        this.detalhes.modoSisfreteAny = this.detalhes_edit.modoSisfreteAny;

        this.saveAnyMarket = false;
      } else if(integradora == 'notifyAnyMarket') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;
        this.saveNotifyAnyMarket = false;
      } else if(integradora == 'Wake') {
        this.detalhes.apptokenWake = this.detalhes_edit.apptokenWake;
        this.detalhes.modoSisfreteWake = this.detalhes_edit.modoSisfreteWake;
        this.detalhes.appstatuspedidosWake = this.detalhes_edit.appstatuspedidosWake;

        this.saveWake = false;
      } else if(integradora == 'Tray') {
        this.detalhes.appTray_code = this.detalhes_edit.appTray_code;
        this.detalhes.appTray_api_address = this.detalhes_edit.appTray_api_address;
        this.detalhes.appTray_token = this.detalhes_edit.appTray_token;
        if(this.detalhes_edit.integracao == 5) {
          this.detalhes.integracao = 5;
        }

        this.saveTray = false;
      } else if(integradora == 'notifyWake') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;
        this.saveNotifyWake = false;
      } else if(integradora == 'Fast') {
        this.detalhes.FastCommerce.StoreId = this.detalhes_edit.FastCommerce.StoreId;
        this.detalhes.FastCommerce.StoreName = this.detalhes_edit.FastCommerce.StoreName;
        this.detalhes.FastCommerce.Username = this.detalhes_edit.FastCommerce.Username;
        this.detalhes.FastCommerce.Password = this.detalhes_edit.FastCommerce.Password;

        this.saveFast = false;
      } else if(integradora == 'notifyFast') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;

        this.saveNotifyFast = false;
      } else if(integradora == 'notifyTray') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;

        this.saveNotifyTray = false;
      } else if(integradora == 'Aleph') {
        this.detalhes.apikey = this.detalhes_edit.apikey;
        this.detalhes.accountid = this.detalhes_edit.accountid;
        this.detalhes.productAlephcrm = this.detalhes_edit.productAlephcrm;

        this.saveAleph = false;
      } else if(integradora == 'notifyAleph') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;

        this.saveNotifyAleph = false;
      } else if(integradora == 'Tiny') {
        this.detalhes.appSisfreteTiny = this.detalhes_edit.appSisfreteTiny;
        this.detalhes.tokenSisfreteTiny = this.detalhes_edit.tokenSisfreteTiny;

        this.saveTiny = false;
      } else if(integradora == 'Aton') {
        this.detalhes.appSisfreteAton = this.detalhes_edit.appSisfreteAton;
        this.detalhes.tokenSisfreteAton = this.detalhes_edit.tokenSisfreteAton;

        this.saveAton = false;
      } else if(integradora == 'notifyIntLog') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;

        this.saveNotifyIntLog = false;
      } else if(integradora == 'Integrador Logístico Sisfrete') {
        this.detalhes.apikeyIntLog = this.detalhes_edit.apikeyIntLog;
      } else if(integradora == 'Mercado Livre') {
        this.detalhes.appSisfreteML = this.detalhes_edit.appSisfreteML;
        this.detalhes.authorizationML = this.detalhes_edit.authorizationML;

        this.saveMercadoLivre = false;
      } else if(integradora == 'notifyMercadoLivre') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;

        this.saveNotifyMercadoLivre = false;
      } else if(integradora == 'Shopee') {
        this.detalhes.appSisfreteShopee = this.detalhes_edit.appSisfreteShopee;
        this.detalhes.appShopeeSellerCode = this.detalhes_edit.appShopeeSellerCode;
        this.detalhes.appShopeeSellerShopId = this.detalhes_edit.appShopeeSellerShopId;
        this.detalhes.appShopeeErpCode = this.detalhes_edit.appShopeeErpCode;
        this.detalhes.appShopeeErpShopId = this.detalhes_edit.appShopeeErpShopId;
        this.detalhes.appShopeeToken = this.detalhes_edit.appShopeeToken;
        this.appShopeeShopId = this.appShopeeShopId_edit;

        this.saveShopee = false;
      } else if(integradora == 'notifyShopee') {
        this.notifications.desserts[this.indice].timeline.selected = this.notifications_edit.desserts[this.indice].timeline.selected;

        this.saveNotifyShopee = false;
      } else if(integradora == 'Email') {
        this.notifications.desserts[1].timeline.selected = this.notifications_edit.desserts[1].timeline.selected;
      } else if(integradora == 'SMS') {
        this.notifications.desserts[0].timeline.selected = this.notifications_edit.desserts[0].timeline.selected;
      }
    },

    async saveForm(sendForm) {
      sendForm.notify = [];
      for(let i = 0; i < this.notifications.desserts.length; i++) {
        if(this.notifications.desserts[i].timeline.selected.length > 0) {
          let channel = this.notifications.desserts[i].channel;
          let type = this.notifications.desserts[i].type;
          if(type == "Todos Pedidos") {
            type = 1;
          } else if(type == "Somente") {
            type = 0;
          }
          let timeline = this.notifications.desserts[i].timeline.selected;

          sendForm.notify.push({ channel: channel, type: type, timeline: timeline });
        }
      }
      let retSavefForm = false;
      await axios
        .put(`${baseApiUrl}/empresas/${this.codigo_empresa}`, sendForm)
        .then((response) => {
          retSavefForm = response.data.retorno;
        })
        .catch(() => {
          retSavefForm = -1;
        })
      return retSavefForm;
    },

    btnSave(sms = false) {
      this.saveDetalhes = true;

      if(sms) {
        let accent = this.detalhes.message_notify_sms;
        this.detalhes.message_notify_sms = accent.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        // this.detalhes.message_notify_sms = this.detalhes.message_notify_sms.replace(/\r?\n|\r/g, "");
      }
    },

    btnSaveCorreios() {
      this.saveCorreios = true;
    },

    btnSaveJad() {
      this.saveJad = true;
    },

    btnSaveProceda() {
      this.saveProceda = true;
    },

    btnSaveBling() {
      this.saveBling = true;
    },

    btnSaveNuvem() {
      this.saveNuvem = true;
    },

    btnSaveAnyMarket() {
      this.saveAnyMarket = true;
    },

    btnSaveWake() {
      this.saveWake = true;
    },

    btnSaveFast() {
      this.saveFast = true;
    },

    btnSaveTray() {
      this.saveTray = true;
    },

    btnSaveAleph() {
      this.saveAleph = true;
    },

    btnSaveIntLog() {
      this.saveIntLog = true;
    },

    btnSaveTiny() {
      this.saveTiny = true;
    },

    btnSaveAton() {
      this.saveAton = true;
    },

    btnSaveMercadoLivre() {
      this.saveMercadoLivre = true;
    },

    btnSaveNotifyBling() {
      this.saveNotifyBling = true;
    },

    btnSaveNotifyNuvem() {
      this.saveNotifyNuvem = true;
    },

    btnSaveNotifyAnyMarket() {
      this.saveNotifyAnyMarket = true;
    },

    btnSaveNotifyWake() {
      this.saveNotifyWake = true;
    },

    btnSaveNotifyFast() {
      this.saveNotifyFast = true;
    },

    btnSaveNotifyTray() {
      this.saveNotifyTray = true;
    },

    btnSaveNotifyAleph() {
      this.saveNotifyAleph = true;
    },

    btnSaveNotifyIntLog() {
      this.saveNotifyIntLog = true;
    },

    btnSaveNotifyTiny() {
      this.saveNotifyTiny = true;
    },

    btnSaveNotifyMercadoLivre() {
      this.saveNotifyMercadoLivre = true;
    },

    btnSaveNotifyShopee() {
      this.saveNotifyShopee = true;
    },

    btnSaveShopee() {
      this.saveShopee = true;
    },

    btnSaveEmail() {
      this.saveEmail = true;
    },

    btnSaveSMS() {
      this.saveSMS = true;

      let accent = this.detalhes.message_notify_sms;
      this.detalhes.message_notify_sms = accent.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.detalhes.message_notify_sms = this.detalhes.message_notify_sms.replace(/(\r\n|\n|\r)/gm, "");
    },

    async stringRandomica(size = 16) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let token = '';
      for (let i = 0; i < size; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters.charAt(randomIndex);
      }
      return token;
    },

    /**
     * Determina o que acontece quando clica em algum dos botões de
     * Integradores log., Integradoras ou Serviços
     */
    setConfig(item) {
      if(item == 'showIntegradoresLog') {
        if(this.showIntegradoresLog == false){
          this.showIntegradoresLog = true;
          this.showIntegradoras = false;
          this.showServicos = false;
        }else{
          this.showIntegradoresLog = false;
        }
      }else if(item == 'showIntegradoras'){
        if(this.showIntegradoras == false){
          this.showIntegradoresLog = false;
          this.showIntegradoras = true;
          this.showServicos = false;
        }else {
          this.showIntegradoras = false;
        }
      }else if(item == 'showServicos'){
        if(this.showServicos == false){
          this.showIntegradoresLog = false;
          this.showIntegradoras = false;
          this.showServicos = true;
        }else {
          this.showServicos = false;
        }
      }
      this.showConfig = false;
    },

    /** Quando clica em alguma integradora ou serviço */
    carregarConfig(item) {
      if(this.showConfig && this.titleConfig == item.nome){
        this.showConfig = false;
      }else{
        this.showConfig = true;
      }
      this.titleConfig = item.nome;
    },

    getColor(type, cor) {
      let color = cor;
      if (type == 1) {
        color = "#FF0000"; // vermelho
      }
      return color;
    },
  },

  watch: {
    "detalhes.tokenSisfreteTiny": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.appSisfreteTiny = 1;
        } else {
          this.detalhes.appSisfreteTiny = 0;
        }
      }
    },

    "detalhes.tokenSisfreteAton": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.appSisfreteAton = 1;
        } else {
          this.detalhes.appSisfreteAton = 0;
        }
      }
    },

    "detalhes.authorizationML": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.appSisfreteML = 1;
        }
      }
    },

    "detalhes.apikeyIntLog": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.pedidosIntLog = 1;
        }
      }
    },

    "detalhes.appTray_api_address": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.appSisfreteTray = 1;
        }
      }
    },

    "detalhes.FastCommerce.StoreId": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.pedidosFastCommerce = 1;
        }
      }
    },

    "detalhes.apptokenWake": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.appSisfreteWake = 1;
        }
      }
    },

    "detalhes.apptokenAny": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.appSisfreteAny = 1;
        }
      }
    },

    "detalhes.urlNuvemShop": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.appSisfreteNS = 1;
        }
      }
    },

    "detalhes.apeliFtpProceda": {
      handler: function(val) {
        if(val != '' && val != null) {
          this.detalhes.ftpProceda = 1;
        }
      }
    },

    "detalhes.notifications": {
      handler: function(val) {
        if(val) {
          this.loadNotify();
        }

        if(this.titleConfig == 'Email' || this.titleConfig == 'SMS') {
          this.showConfig = false;
        }
      }
    },

    "selectModoNotify": {
      handler: function(val, oldVal) {
        if(val != oldVal) {
          this.notifications.desserts[this.indice].type = this.selectModoNotify;
        }
      }
    }
  },

  computed: {
    getTitleStatusTray() {
      return `Os Pedidos serão baixados conforme Status Selecionados: ( ${this.detalhes.appTray_status_pedidos.length} )`;
    },

    getTitleStatusWake() {
      return `Os Pedidos serão baixados conforme Status Selecionados: ( ${this.detalhes.appstatuspedidosWake.length} )`;
    }
  },
}
</script>

<style>
/** Se o Mercado Livre vier com erro no token */
.erro_canal {
  border: 3px solid #FF0000 !important;
}

.cardStyle {
  width: 110px;
}
</style>